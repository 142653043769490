import { HttpStatusCode } from '@solidjs/start';
import { useAnalytics, useTrackEvent } from '@troon/analytics';
import { createEffect, createMemo, createSignal, onMount, Show, Suspense, useContext } from 'solid-js';
import {
	Button,
	Container,
	Dialog,
	DialogContent,
	Errors,
	Form,
	Heading,
	HiddenFields,
	HorizontalRule,
	Link,
	Page,
	Section,
} from '@troon/ui';
import { createStore, produce } from 'solid-js/store';
import { IconArrowLeftMd } from '@troon/icons/arrow-left-md';
import { IconArrowRightMd } from '@troon/icons/arrow-right-md';
import { createAsync, useAction, useNavigate, useSubmission } from '@solidjs/router';
import { captureException, withScope } from '@sentry/solidstart';
import { Meta, Title } from '@solidjs/meta';
import { z } from 'zod';
import { IconCircleWarning } from '@troon/icons/circle-warning';
import { decode } from 'decode-formdata';
import { Grid, GridFive, GridSeven } from '../../../../components/layouts/grid';
import { CheckoutStep } from '../../../../components/reserve-tee-time/checkout-step';
import { useUser } from '../../../../providers/user';
import { AuthFlow } from '../../../../partials/auth/auth';
import { authHeaders } from '../../../../partials/auth/inline';
import { useUtmParams } from '../../../../providers/utm';
import { SelectCreditCard, SelectedCreditCard } from '../../../../components/reserve-tee-time/credit-card';
import { RequestPhoneNumber } from '../../../../components/request-phone-number';
import { gql, mutationAction, useMutation } from '../../../../graphql';
import { Waiver } from '../../../../partials/waiver';
import { usePersisted } from '../../../../providers/persistence-store';
import { FacilityCtx } from '../../../../providers/facility';
import { getConfigValue } from '../../../../modules/config';
import { cachedQuery } from '../../../../graphql/cached-get';
import { PaymentInfo } from '../../../../components/payment-info';
import { coerceBoolean, createSearchStore } from '../../../../modules/search-store';
import { PlayersSelect } from '../../../../components/reserve-tee-time/players-select';
import { TeeTimeInfoHeader } from '../../../../components/reserve-tee-time/tee-time-info';
import { RateSelect } from '../../../../components/reserve-tee-time/rate-select';
import { RewardsPointsEarned } from '../../../../components/reserve-tee-time/rewards-points';
import ConfirmSubscription from '../../../../components/confirm-subscription';
import type { WaiverHandler } from '../../../../partials/waiver';
import type { CreditCard } from '../../../../graphql';
import type { RouteDefinition, RouteSectionProps } from '@solidjs/router';

type Steps = 'rate' | 'players' | 'auth' | 'payment' | 'phone' | 'ready';

const order: Array<Steps> = ['rate', 'players', 'auth', 'payment', 'phone', 'ready'];
const stepFlow = 'Reserve Tee Time';
const stepOptions = { noDuplicates: true };

type Store = {
	current: Steps;
	steps: Record<Steps, { complete: boolean; skipped: boolean }>;
};

const schema = z.object({
	players: z.coerce.number().min(1).max(4).default(1),
	rateId: z.string().nullish(),
	triggerId: z.string().nullish(),
	// Access was purchased and redirected back to this tee time
	subscriptionId: z.string().nullish(),
	productId: z.string().nullish(),
	confirmed: coerceBoolean().nullish(),
});

export default function ReserveTeeTime(props: RouteSectionProps) {
	const user = useUser();
	const facility = useContext(FacilityCtx);
	const trackEvent = useTrackEvent();

	// Temporary for experiment 'booking-flow=stepped'
	const analytics = useAnalytics();
	onMount(() => {
		analytics?.startSessionRecording();
	});

	// UTM params are sent to the reservation mutation for tracking purposes
	const utm = useUtmParams();

	const [filters, setFilters] = createSearchStore(schema);

	const [store, setStore] = createStore<Store>({
		current: 'rate',
		steps: {
			rate: { complete: false, skipped: false },
			players: { complete: false, skipped: false },
			auth: { complete: !!user()?.me, skipped: !!user()?.me },
			payment: { complete: false, skipped: false },
			phone: { complete: !!user()?.me.phoneNumber, skipped: !!user()?.me.phoneNumber },
			ready: { complete: false, skipped: false },
		},
	});
	const [card, setCard] = createSignal<CreditCard>();
	const [hasPhone, setHasPhone] = createSignal(user()?.me ? !!user()?.me.phoneNumber : undefined);
	const [form, setForm] = createSignal<HTMLFormElement>();
	const [waiver, setWaiver] = createSignal<WaiverHandler>();
	const [clickedNotReady, setClickedNotReady] = createSignal(false);
	const [persisted] = usePersisted();
	const navigate = useNavigate();

	const [confirmSubscriptionOpen, setConfirmSubscriptionOpen] = createSignal(
		!!(filters.subscriptionId && filters.productId),
	);

	const supportsAccess = createMemo(
		() =>
			!!user()?.activeTroonCardSubscription ||
			!(
				!facility()?.facility.supportsTroonAccessCourseSiteUpsell &&
				utm().campaign === 'course-booking-link' &&
				utm().source === facility()?.facility.slug
			),
	);

	const data = createAsync(
		async () =>
			getRateInfo({
				rateId: filters.rateId,
				teeTimeId: props.params.teeTimeId!,
				players: filters.players,
			}),
		{ deferStream: true },
	);

	const mutation = reserve(async () => {
		// Support for Google Merchant Actions (eg, Book with Troon link on Google search results)
		if (persisted.rwg_token && persisted.rwg_token.expires > Date.now() && persisted.rwg_merchant) {
			const merchantChanged = facility()?.facility.slug === persisted.rwg_merchant.value ? 2 : 1;
			fetch(getConfigValue('GOOGLE_ACTIONS_ENDPOINT'), {
				method: 'POST',
				body: JSON.stringify({
					conversion_partner_id: getConfigValue('GOOGLE_ACTIONS_PARTNER_ID'),
					rwg_token: persisted.rwg_token.value,
					merchant_changed: `${merchantChanged}`,
				}),
			});
		}
	});
	const reserveAction = useMutation(mutation);
	const triggerFormAction = useAction(reserveAction);
	const submission = useSubmission(reserveAction);

	createEffect(() => {
		const err = submission.error ?? submission.result?.error?.graphQLErrors[0];
		if (err && submission.input) {
			withScope((scope) => {
				scope.setLevel('warning');
				const inputData = decode(submission.input[0]);
				scope.setExtras(inputData);
				scope.captureException(err);
			});
		}
	});

	createEffect(() => {
		setStore('steps', 'auth', 'complete', !!user()?.me);
	});

	// Ensures the contact information step gets set to required or not
	createEffect(() => {
		if (user()?.me && !user()?.me.phoneNumber) {
			setHasPhone(false);
		} else if (user()?.me.phoneNumber && hasPhone() === undefined) {
			setStore('steps', 'phone', { complete: true, skipped: true });
			setHasPhone(true);
		}
	});

	// Ensure the payment information is set to required or not
	createEffect(() => {
		const required = !!data()?.info.paymentInfo.requiresCreditCard;
		setStore('steps', 'payment', { complete: !required || !!card(), skipped: !required });
	});

	// Ensure the player count selection is within the min/max range
	createEffect(() => {
		const max = data()?.info.rate.maxPlayers ?? 4;
		const min = data()?.info.rate.minPlayers ?? 1;
		if (filters.players > max) {
			setFilters('players', max);
		}
		if (filters.players < min) {
			setFilters('players', min);
		}
	});

	createEffect(() => {
		if (store.current === 'ready' && clickedNotReady()) {
			setClickedNotReady(false);
		}
	});

	/**
	 * Continues to the next incomplete step in the flow
	 */
	function next() {
		let start = order.indexOf(store.current);
		if (start < 0) {
			return;
		}

		for (; start < order.length - 1; start += 1) {
			const step = order[start]!;
			if (!store.steps[step].complete) {
				setStore('current', step);
				return;
			}
			trackEvent('stepCompleted', { stepFlow, stepName: step, stepSkipped: store.steps[step].skipped }, stepOptions);
		}

		setStore('current', order[order.length - 1]!);
	}

	/**
	 * Completes the current step and auto-advances to the next incomplete step in the flow
	 */
	function complete() {
		setStore(
			produce((s) => {
				s.steps[s.current].complete = true;
			}),
		);
		next();
	}

	/**
	 * Returns to the named step for editing
	 * @param step {Steps}
	 */
	function edit(step: Steps) {
		setStore({ current: step });
	}

	/**
	 * Get the step's state as a string enum
	 * @param step {Steps}
	 * @returns String enum for step state
	 */
	function stepState(step: Steps) {
		return store.current === step ? 'current' : store.steps[step].complete ? 'completed' : 'waiting';
	}

	function getIndex(step: Steps) {
		// eslint-disable-next-line solid/reactivity
		return order.slice(0, order.indexOf(step)).reduce((index, step) => {
			return index + (!store.steps[step].skipped ? 1 : 0);
		}, 1);
	}

	return (
		<div class="-mb-12 min-h-screen bg-neutral-100">
			<Title>Book Tee Time | {facility()!.facility.name} | Troon</Title>
			<Meta name="robots" content="noindex" />
			<Meta name="googlebot" content="noindex" />
			<Container>
				<Page class="gap-8 md:gap-12">
					<Section>
						<Heading as="h1">Book Tee Time</Heading>
					</Section>
					<Section appearance="contained" class="lg:hidden">
						<TeeTimeInfoHeader
							info={data.latest?.info}
							facilityName={facility()!.facility.name}
							facilitySlug={facility()!.facility.slug}
							facilityLogo={facility()!.facility.metadata?.logo?.url}
						/>
					</Section>

					<Grid class="gap-y-8 lg:gap-y-8 xl:gap-y-8">
						<GridSeven class="flex flex-col gap-4">
							<Show
								when={data.latest}
								fallback={
									<>
										<HttpStatusCode code={404} />
										<Section appearance="contained">
											<Heading as="h2">Unavailable</Heading>
											<p>This tee time is no longer available.</p>
											<Button
												as={Link}
												onClick={(e) => {
													if (history.length > 1) {
														e.preventDefault();
														navigate(-1);
														return;
													}
												}}
												href={`/course/${facility()!.facility.slug}/reserve-tee-time`}
												appearance="tertiary"
											>
												<IconArrowLeftMd /> See available Tee Times
											</Button>
										</Section>
									</>
								}
							>
								{/**
								 * Rate selection
								 */}
								<CheckoutStep
									state={stepState('rate')}
									step={getIndex('rate')}
									title="Choose Your Rate"
									onEdit={() => edit('rate')}
									value={`${data.latest?.info.rate.name} ${data.latest?.info.rate.description ? ` • ${data.latest?.info.rate.description}` : data.latest?.info.rate.isPublicRate ? ' • Standard rate' : ''}`}
								>
									<RateSelect
										rates={[
											...(data.latest?.info.availableRates ?? []),
											...(data.latest?.info.troonSubscriptionRate && supportsAccess()
												? [data.latest?.info.troonSubscriptionRate]
												: []),
										]}
										onSelect={(rateId) => setFilters('rateId', rateId)}
										selected={filters.rateId || data.latest?.info.rate.id}
										userHasAccess={
											// prevent showing the  upsell dialog if we're confirming the subscription
											!!user()?.activeTroonCardSubscription || !!(filters.subscriptionId && filters.productId)
										}
									/>

									<Button onClick={complete}>
										Continue<span class="sr-only"> with this rate</span>
									</Button>
								</CheckoutStep>

								{/**
								 * Player select
								 */}
								<CheckoutStep
									state={stepState('players')}
									step={getIndex('players')}
									title="Number of Players"
									onEdit={() => edit('players')}
									value={`${data.latest?.info.players} player${(data.latest?.info.players ?? 1) > 1 ? 's' : ''}`}
								>
									<Suspense>
										<PlayersSelect
											maxPlayers={data.latest?.info.rate.maxPlayers ?? 4}
											minPlayers={data.latest?.info.rate.minPlayers ?? 1}
											rateName={data.latest?.info.rate.name}
											players={filters.players}
											setPlayers={(count: number) => setFilters('players', count)}
											faqs={
												data.latest?.info.rate.isTroonCardRate
													? filters.players > 1
														? 'troon-access-booking'
														: 'individual-access-faqs'
													: filters.players > 1
														? 'reservation-details-multiple-player-faqs'
														: 'individual-booking-faqs'
											}
										/>
									</Suspense>

									<Button onClick={complete}>
										Continue<span class="sr-only"> with {filters.players} players</span>
									</Button>
								</CheckoutStep>

								{/**
								 * Sign in
								 */}
								<Show when={!store.steps.auth.skipped}>
									<CheckoutStep
										state={stepState('auth')}
										step={getIndex('auth')}
										title="Log in or Sign up"
										value={
											user()?.me ? (
												<div>
													<p>
														{user()?.me.firstName} {user()?.me.lastName}
													</p>
													<p class="truncate">{user()?.me.email}</p>
													<p>Rewards #{user()?.me.troonRewardsId}</p>
												</div>
											) : null
										}
									>
										<Show when={!user()?.me}>
											<AuthFlow
												inline
												onComplete={() => {
													if (
														user()?.activeTroonCardSubscription &&
														data.latest?.info.rate.isPublicRate &&
														data.latest?.info.troonSubscriptionRate
													) {
														setFilters('rateId', data.latest?.info.troonSubscriptionRate.id);
														edit('rate');
														return;
													}
													next();
												}}
												headers={authHeaders}
												showSteps={false}
											/>
										</Show>
									</CheckoutStep>
								</Show>

								{/**
								 * Payment Method / Credit Card
								 */}
								<Show when={!store.steps.payment.skipped}>
									<CheckoutStep
										state={stepState('payment')}
										step={getIndex('payment')}
										title="Payment Method"
										onEdit={() => edit('payment')}
										value={<SelectedCreditCard card={card()} />}
									>
										<SelectCreditCard
											onComplete={(card) => {
												setCard(card);
												complete();
											}}
										/>
									</CheckoutStep>
								</Show>

								{/**
								 * Contact Information / Phone number input
								 */}
								<Show when={!hasPhone()}>
									<CheckoutStep
										state={stepState('phone')}
										step={getIndex('phone')}
										title="Contact Information"
										onEdit={() => edit('phone')}
										value={<Show when={user()?.me}>{(me) => <p>{me().phoneNumber}</p>}</Show>}
									>
										<RequestPhoneNumber
											buttonLabel="Continue"
											onCancel={user()?.me.phoneNumber ? () => complete() : undefined}
											onSuccess={complete}
										/>
									</CheckoutStep>
								</Show>
							</Show>
						</GridSeven>

						<GridFive>
							<Section
								appearance="contained"
								// eslint-disable-next-line tailwindcss/no-arbitrary-value
								class="sticky top-8 max-h-[calc(100dvh-4rem)] overflow-y-auto px-0 md:px-0"
							>
								<div class="hidden px-4 md:px-8 lg:block">
									<TeeTimeInfoHeader
										info={data()?.info}
										facilityName={facility()!.facility.name}
										facilitySlug={facility()!.facility.slug}
										facilityLogo={facility()!.facility.metadata?.logo?.url}
										showInfo={!!data.latest?.info}
									/>
								</div>
								<Show when={data.latest?.info}>
									<div class="hidden lg:block">
										<HorizontalRule />
									</div>

									<div class="flex flex-col gap-2 px-4 md:px-8">
										<Heading as="h2" size="h5">
											Payment info
										</Heading>

										<PaymentInfo receipt={data()?.info.paymentInfo.receipt} />

										<Suspense>
											<RewardsPointsEarned rewardPointsEarned={data()?.info.paymentInfo.rewardPointsEarned} />
										</Suspense>
									</div>
								</Show>
							</Section>
						</GridFive>

						<GridSeven class="flex flex-col gap-4">
							<Section>
								<Show when={data.latest?.info.courseNotes}>
									{(notes) => (
										<div class="flex flex-col gap-2">
											<Heading as="h2" size="h5">
												Notes from the Course
											</Heading>
											<p>{notes()}</p>
										</div>
									)}
								</Show>

								<Show when={data.latest?.info.cancellationInfo.displayMessage}>
									{(cancellationPolicy) => (
										<div class="flex flex-col gap-2">
											<Heading as="h2" size="h5">
												Cancellation Policy
											</Heading>
											<p>{cancellationPolicy()}</p>
										</div>
									)}
								</Show>

								<Waiver ref={setWaiver} />

								<Show when={data.latest}>
									<Form document={reserveMutation} action={reserveAction} ref={setForm} class="relative">
										<HiddenFields
											data={{
												reserveId: data.latest?.info.reserveId,
												creditCardId: card()?.id,
												triggerId: filters.triggerId,
												utm: utm(),
												// Facility and value are used for analytics tracking purposes
												__facilitySlug: facility()?.facility.slug,
												__value: data.latest?.info.paymentInfo.receipt.total.value ?? 0,
											}}
										/>
										<Button
											type="submit"
											disabled={store.current !== 'ready'}
											onClick={(e) => {
												e.preventDefault();
												function complete() {
													const reserveData = new FormData(form());
													triggerFormAction(reserveData);
												}

												waiver()?.confirm(complete);
											}}
										>
											Book Tee Time <IconArrowRightMd />
										</Button>
										<Show when={store.current !== 'ready'}>
											<div
												class="absolute inset-0 cursor-not-allowed"
												onClick={() => {
													if (store.current !== 'ready') {
														setClickedNotReady(true);
													}
												}}
											/>
										</Show>
										<Show when={clickedNotReady()}>
											<p
												aria-live="assertive"
												class="flex items-center justify-center gap-1 font-semibold text-red-500"
											>
												<IconCircleWarning /> Please complete all steps to continue booking.
											</p>
										</Show>
										<Errors />
									</Form>
								</Show>
							</Section>
						</GridSeven>
					</Grid>
				</Page>
			</Container>

			<Show when={filters.subscriptionId && filters.productId && user()?.me}>
				<Dialog
					key="subscription-confirmation"
					defaultOpen
					open={confirmSubscriptionOpen()}
					onOpenChange={setConfirmSubscriptionOpen}
				>
					<DialogContent>
						<ConfirmSubscription
							subscriptionId={filters.subscriptionId!}
							productId={filters.productId!}
							onContinue={() => {
								setConfirmSubscriptionOpen(false);
							}}
							continueText="Continue booking"
						/>
					</DialogContent>
				</Dialog>
			</Show>
		</div>
	);
}

const rateInfoQuery = gql(`query teeTimeInfo(
	$teeTimeId: String!
	$rateId: String
	$players: Int
) {
	info: courseTeeTimeReservationInfo(
		teeTimeId: $teeTimeId
		rateId: $rateId
		players: $players
	) {
		...TeeTimeInfo
		reserveId
		course {
			requiresCCAtBooking
		}
		rate {
			id
			minPlayers
			maxPlayers
			name
			description
			isTroonCardRate
			isPrePaid
			isPublicRate
		}
		availableRates {
			...RateSelection
		}
		troonSubscriptionRate {
			id
			...RateSelection
		}
		players
		holes
		paymentInfo {
			requiresCreditCard
			rewardPointsEarned
			receipt {
				...PaymentInfo
				total { value }
			}
		}
		courseNotes
		cancellationInfo {
			displayMessage
		}
	}
}`);

const getRateInfo = cachedQuery(rateInfoQuery, {
	retry: {
		retryIf: (result) => {
			if (result.error?.message.includes('for a tee time in the past')) {
				return false;
			}
			return !!result.error;
		},
		maxAttempts: 3,
		delay: 200,
	},
	onError(error) {
		if (!error.message.includes('for tee time in the past')) {
			captureException(error);
		}
		return null;
	},
});

const reserveMutation = gql(`
mutation reserveTeeTime(
	$reserveId: String!
	$creditCardId: String
	$triggerId: String
	$utm: UTMAttributesInput
) {
	reserveTeeTime(
		reserveId: $reserveId
		creditCardId: $creditCardId
		teeTimeAlertTriggerId: $triggerId
		utmAttributes: $utm
	) {
		id
		courseId
		playerCount
	}
}
`);

const reserve = (onSuccess: () => Promise<void>) =>
	mutationAction(reserveMutation, {
		timeout: 45_000,
		revalidates: ['home', 'allReservations'],
		redirect: (data) => `/reservation/${data?.reserveTeeTime.id}/confirmed`,
		onSuccess,
		track: {
			event: 'reserveTeeTime',
			transform: (data, res) => ({
				facilitySlug: data.get('__facilitySlug') as string,
				value: parseFloat(data.get('__value') as string),
				players: res?.reserveTeeTime.playerCount,
				courseId: res?.reserveTeeTime.courseId,
			}),
		},
	});

export const route = {
	info: { nav: { appearance: 'minimal', sticky: false }, banner: { hide: true } },
} satisfies RouteDefinition;

export { ReserveTeeTime };
